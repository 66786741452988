@media screen and (max-width: 420px) {
    .aspect-w-16 {
      width: 80vw;
      height: calc(70vw * 9 / 16);
    }
  }
  


  @media screen and (max-width: 280px) {
    .aspect-w-16 {
      width: 50vw;
      height: calc(40vw * 9 / 16);
    }
  }
  