.amky-container {
    width: 100%;
  }
  
  .amky-header {
    width: 100vw;
    height: 50vh;
    position: relative;
  }
  
  .amky-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
  }
  
  .amky-image {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .amky-text {
    position: absolute;
    top: 70%;
    max-width: 1240px;
    width: 100%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 10;
    padding: 8px;
  }
  
  .amky-text h2 {
    padding-top: 16px;
  }
  
  .amky-content {
    max-width: 1240px;
    margin: 0 auto;
    padding: 8px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 32px;
    padding-top: 64px;
  }
  
  .amky-info {
    grid-column: span 4;
  }
  
  .amky-button {
    padding: 8px 16px;
    margin-top: 16px;
    margin-right: 8px;
    cursor: pointer;
  }
  
  .amky-tech {
    grid-column: span 4;
    @media (min-width: 768px) {
      grid-column: span 1;
    }
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 16px;
  }
  
  .tech-header {
    text-align: center;
    font-weight: bold;
    padding-bottom: 8px;
  }
  
  .tech-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media (min-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  
  .tech-item {
    color: #666;
    padding: 12px 0;
    display: flex;
    align-items: center;
  }
  
  .amky-back {
    text-decoration: none;
  }
  
  .amky-back-text {
    text-decoration: underline;
    cursor: pointer;
  }
  