/* Estilos generales para la sección del héroe */
.hero-section {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #302f2f; /* Color de texto blanco */
}

/* Estilos para el fondo de partículas */
.background-image {
  width: 100%;
  height: 100%;
}

/* Estilos para el contenido en el centro */
.content-container {
  z-index: 1; /* Asegura que el contenido esté sobre las partículas */
}

/* Estilos para el texto */
.text-container {
  max-width: 800px;
  margin: 0 auto;
}

/* Estilos para el título */
.text-container h2 {
  font-size: 5xl;
  font-weight: bold;
  padding: 12px 0;
  color: #424242; /* Puedes personalizar el color de texto */
}

/* Estilos para los párrafos */
.text-container p {
  font-size: lg;
  margin-bottom: 6px;
}

/* Estilos para el enlace del botón */
.text-container a {
  display: inline-block;
  background-color: #5651e5; /* Color de fondo del botón */
  color: #f0eded; /* Color de texto del botón */
  padding: 6px 12px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}

/* Estilos para el enlace del botón al pasar el ratón */
.text-container a:hover {
  background-color: #4743e0; /* Color de fondo del botón al pasar el ratón */
}
