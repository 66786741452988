/* FloatingWhatsAppButton.css */
.floating-whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background: linear-gradient(to bottom, rgba(173, 216, 230, 1), rgba(0, 0, 0, 0));
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s ease;
}

.floating-whatsapp-button:hover {
  background: linear-gradient(to bottom, rgba(173, 216, 230, 8), rgba(0, 0, 0, 0) );
}

.floating-whatsapp-button img {
  width: 50%;
  height: auto;
}
