.tiancheng-container {
    width: 100%;
  }
  
  .tiancheng-banner {
    width: 100vw;
    height: 50vh;
    position: relative;
  }
  
  .tiancheng-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
  }
  
  .tiancheng-img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .tiancheng-title-container {
    position: absolute;
    top: 70%;
    max-width: 1240px;
    width: 100%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 10;
    padding: 8px;
  }
  
  .tiancheng-content {
    max-width: 1240px;
    margin: 0 auto;
    padding: 8px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 32px;
    padding-top: 64px;
  }
  
  .tiancheng-description {
    grid-column: span 4;
  }
  
  .tiancheng-tech-card {
    grid-column: span 4;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 8px;
  }
  
  .tiancheng-tech-title {
    text-align: center;
    font-weight: bold;
    padding-bottom: 8px;
  }
  
  .tiancheng-tech-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  
  .tiancheng-tech-item {
    color: #666;
    padding: 8px 0;
    display: flex;
    align-items: center;
  }
  
  .tiancheng-button {
    padding: 8px 16px;
    margin-top: 16px;
    margin-right: 8px;
  }
  
  .tiancheng-back-link {
    text-decoration: underline;
    cursor: pointer;
  }
  