.gustautos-container {
    width: 100%;
  }
  
  .gustautos-header {
    width: 100vw;
    height: 50vh;
    position: relative;
  }
  
  .gustautos-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
  }
  
  .gustautos-image {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .gustautos-text {
    position: absolute;
    top: 70%;
    max-width: 1240px;
    width: 100%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 10;
    padding: 8px;
  }
  
  .gustautos-text h2 {
    padding-top: 16px;
  }
  
  .gustautos-content {
    max-width: 1240px;
    margin: 0 auto;
    padding: 8px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 32px;
    padding-top: 64px;
  }
  
  .gustautos-info {
    grid-column: span 4;
  }
  
  .gustautos-button {
    padding: 8px 16px;
    margin-top: 16px;
    margin-right: 8px;
    cursor: pointer;
  }
  
  .gustautos-tech {
    grid-column: span 3;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 6px;
  }
  
  .tech-header {
    text-align: center;
    font-weight: bold;
    padding-bottom: 6px;
  }
  
  .tech-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  
  .tech-item {
    color: #666;
    padding: 6px 0;
    display: flex;
    align-items: center;
  }
  
  .gustautos-back {
    text-decoration: none;
  }
  
  .gustautos-back-button {
    padding: 8px 16px;
    background-color: transparent;
    border: 1px solid #333;
    border-radius: 4px;
    color: #333;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s;
    display: inline-block;
    font-weight: bold;
    outline: none;
  }
  